import React, {
  useState, forwardRef, useEffect,
  useImperativeHandle
} from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import combosService from 'src/services/combosService';
import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  makeStyles,
  Divider,
  Tooltip,
  Button,
  IconButton,
  TextField
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {
  ExpandMore,
  GetAppTwoTone,
  Delete,
} from '@material-ui/icons';
import GeneralBackDrop from 'src/views/commons/GeneralBackDrop';
import { DropzoneArea } from 'material-ui-dropzone';
import renderCellExpand from 'src/views/commons/RenderCellExpand';
import ordenService from 'src/services/ordenService';
import { Download } from 'react-feather';
import ConfirmationDialog from 'src/views/commons/ConfirmationDialog';
import moment from 'moment';
import OrderGeneral from '../OrderCommons/OrderGeneral';

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    display: 'block'
  },
  dropZoneStyle: {
    minHeight: '100px',
  },
  dropZoneText: {
    fontWeight: '200',
    fontFamily: 'sans-serif'
  },
  control: {
    padding: theme.spacing(2),
  },
  tooltip: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    boxShadow: 'none'
  },
  verticalCenter: {
    alignItems: 'center',
    display: 'flex'
  },
  summary: {
    backgroundColor: theme.palette.AccordionSummary.background
  }
}));

const AltaClienteOT = forwardRef(({
  formName,
  comboEstados,
  comboMunicipios,
  setComboMunicipios,
  mapaMunicipios,
  setMapaMunicipios,
  comboGradoEscolar,
  setComboGradoEscolar,
  setServerError,
  setOpenSnackError,
  idOrden,
  setIdOrden,
  formData,
  setFormData,
  idCliente,
  tipoCliente,
  setOpenAltaOrden,
  comboOrdenEstatus,
  comboHiperliga,
  rolUsuario,
  ...props
}, ref) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    setValue,
    setError,
    clearErrors
  } = useForm();
  const classes = useStyles();
  const [acordionGeneral, setAcordionGeneral] = useState(true);
  const [acordionEvaluacion, setAcordionEvaluacion] = useState(true);
  const [acordionArchivos, setAcordionArchivos] = useState(true);
  const [valGradoEscolar, setValGradoEscolar] = useState({});
  const [isLaboral, setIsLaboral] = useState(false);
  const [isEscuela, setIsEscuela] = useState(false);
  const [idDireccion, setIdDireccion] = useState(0);
  const [rowsFiles, setRowsFiles] = useState([]);
  const [folio, setFolio] = useState();
  const [openBack, setOpenBack] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);
  const [openEliminar, setOpenEliminar] = useState(false);
  const [deleteFileRow, setDeleteFileRow] = useState(null);
  const [openAlta, setOpenAlta] = useState(false);
  const [ordenEstatus, setOrdenEstatus] = useState();
  const [fechaCitaStr, setFechaCitaStr] = useState('--');

  const estatusSolicitada = comboOrdenEstatus.filter((estado) => estado.label === 'SOLICITADA');
  const estatusHiperliga = comboHiperliga.filter((estatus) => estatus.label === 'NUEVA');

  const resetForm = () => {
    reset({
      id: 0,
      regCliente: '',
      ordenStatus: '',
      comentarios: '',
      importeFacturar: '',
      fechaSolicitudStr: ''
    });
    combosService.addComboMunicipios(9, mapaMunicipios,
      setComboMunicipios, setMapaMunicipios);
    setRowsFiles([]);
    setValGradoEscolar({});
    console.log(rowsFiles);
    setFolio(ordenService.generarFolio());
  };

  const getComboGradoEscolar = (clienteId, idGradoEscolar) => {
    if (clienteId !== undefined && clienteId > 0) {
      combosService.comboGradoEscolar(clienteId).then((resp) => {
        setComboGradoEscolar(resp);
        if (idGradoEscolar !== undefined && idGradoEscolar > 0) {
          const selecGrado = resp.filter((aux) => aux.value === idGradoEscolar);
          setValGradoEscolar(selecGrado[0]);
        }
      });
    }
  };

  useEffect(() => {
    console.log('Datos de la orden:', formData);
    if (formData.id !== undefined && formData.id > 0) {
      setIdOrden(formData.id);
      setIdDireccion(formData.direccion.id);
      setFolio(formData.folio);
      console.log(idDireccion);
      if (formData.archivos !== null && formData.archivos !== undefined
        && formData.archivos.length > 0) {
        setRowsFiles(formData.archivos);
      }
      const labelOrdenEstatus = comboOrdenEstatus.filter(
        (auxStatus) => auxStatus.value === formData.idOrdenEstatus
      );
      setOrdenEstatus(labelOrdenEstatus[0].label);
      formData.descOrdenEstatus = labelOrdenEstatus[0].label;
      if (formData.fechaCita !== null && formData.fechaCita !== undefined) {
        setFechaCitaStr(moment(new Date(formData.fechaCita)).format('DD/MM/YYYY HH:mm:ss'));
        formData.fechaCitaStr = moment(new Date(formData.fechaCita)).format('DD/MM/YYYY HH:mm:ss');
      } else {
        formData.fechaCitaStr = '--';
      }
      if (formData.fechaTermino !== null && formData.fechaTermino !== undefined
        && moment(new Date(formData.fechaCita)).format('DD/MM/YYYY HH:mm:ss') >= formData.fechaTermino) {
        setFechaCitaStr('--');
      }
      if (formData.fechaTermino !== null && formData.fechaTermino !== undefined) {
        formData.fechaTerminoStr = formData.fechaTermino;
      } else {
        formData.fechaTerminoStr = '--';
      }
    } else {
      setIdOrden(0);
      setFolio(ordenService.generarFolio());
      setFormData({});
      setComboGradoEscolar([]);
    }
    if (formData.direccion?.idEstado !== undefined
      && formData.direccion?.idEstado !== null
      && formData.direccion.idEstado > 0) {
      combosService.addComboMunicipios(formData.direccion.idEstado,
        mapaMunicipios, setComboMunicipios, setMapaMunicipios);
    } else {
      combosService.addComboMunicipios(9, mapaMunicipios,
        setComboMunicipios, setMapaMunicipios);
    }
    console.log('Tipo de Cliente:', tipoCliente);
    if (tipoCliente === 'Escuela') {
      setIsEscuela(true);
      getComboGradoEscolar(idCliente, formData.idGradoEscolar);
    }
    if (tipoCliente === 'Laboral') {
      setIsLaboral(true);
    }
    reset(formData);
    setFormData({});
    console.log('id de estatus de orden:', estatusSolicitada[0].label);
  }, []);

  useImperativeHandle(ref, () => ({
    resetOrdenForm: () => {
      resetForm();
    }
  }));

  const getComboMunicipios = (newValue) => {
    setComboMunicipios([]);
    if (newValue !== null) {
      setValue('idMunicipio', '');
      combosService.addComboMunicipios(newValue, mapaMunicipios,
        setComboMunicipios, setMapaMunicipios);
    }
  };

  const onClickRemove = () => {
    const listFiles = rowsFiles.filter((row) => row.id !== deleteFileRow.id);
    console.log('El archivo a borrar:', deleteFileRow);
    if (deleteFileRow.enServidor) {
      const deleteFile = rowsFiles.filter((row) => row.id === deleteFileRow.id);
      ordenService.deleteOrderFile(deleteFile[0]).catch((error) => console.log(error));
    }
    setRowsFiles(listFiles);
    setOpenEliminar(false);
    setDeleteFileRow(null);
  };

  const agregaArchivos = (fileList) => {
    console.log('Files:', fileList);
    let filesList = [];
    const uploadFileToS3 = async (file) => {
      setOpenBack(true);
      console.log(file);
      if (filesList.length === 0) {
        filesList = rowsFiles.slice();
      }
      const existingFile = filesList.findIndex((auxFile) => auxFile.nombre === file.name);
      let nombreArchivo = file.name;
      const nombreSinExtension = nombreArchivo.substring(0, nombreArchivo.lastIndexOf('.'));
      if (nombreSinExtension.length > 50) {
        const extension = nombreArchivo.substring(nombreArchivo.lastIndexOf('.'), nombreArchivo.length);
        const nombreCorto = nombreSinExtension.substring(0, 50);
        console.log('Longitud del nombre', nombreArchivo.length);
        console.log('Extension del archivo', extension);
        console.log('nombreCorto', nombreCorto + extension);
        nombreArchivo = nombreCorto.trim() + extension;
        setOpenSnackError(true);
        setServerError('El nombre de alguno de sus archivos fue reducido a 50 caracteres.');
      }
      let uploadedFile;
      if (existingFile < 0) {
        const itemFile = {
          id: `${folio}_${nombreArchivo}`,
          nombre: nombreArchivo,
          size: file.size,
          fileBucketName: `${folio}_${nombreArchivo}`,
          comentarios: '',
          estatus: 'NUEVO',
          tipoArchivo: 'GENERAL',
          archivo: file,
          enServidor: false
        };
        filesList.push(itemFile);
        uploadedFile = itemFile;
      } else {
        filesList[existingFile].archivo = file;
        filesList[existingFile].size = file.size;
        if (filesList[existingFile].estatus !== 'NUEVO') {
          filesList[existingFile].estatus = 'ACTUALIZADO';
        }
        uploadedFile = filesList[existingFile];
      }
      console.log('Datos del archivo', uploadedFile);
      /* await ordenService.uploadArchivoAWS(uploadedFile, file); */
      setOpenBack(false);
    };

    fileList.forEach((file) => {
      uploadFileToS3(file);
    });
    setRowsFiles(filesList);
  };

  const descargaMasiva = async () => {
    setOpenBack(true);
    const files = rowsFiles.filter((auxFile) => auxFile.archivo !== undefined)
      .map((filteredFile) => filteredFile.archivo);
    const filesObject = rowsFiles.filter((auxFile) => auxFile.estatus !== 'NUEVO');
    await ordenService.descargarZip(filesObject, files, folio).then((response) => {
      console.log('Respuesta descarga zip', response);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response.data);
      link.download = `${folio}.zip`;
      link.click();
    }).catch((e) => {
      console.log('Error al descargar archivos:', e);
      setOpenBack(false);
      setServerError(e.message);
      setOpenSnackError(true);
    });
    setOpenBack(false);
  };

  const columnsFiles = [
    {
      field: 'tipoArchivo',
      headerName: 'Tipo de Archivo',
      minWidth: 120,
      flex: 1,
      hideSortIcons: true
    },
    {
      field: 'nombre',
      headerName: 'Nombre de Archivo',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'comentarios',
      headerName: 'Comentarios',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'acciones',
      headerName: 'Acciones',
      maxWidth: 60,
      minWidth: 60,
      sortable: false,
      disableClickEventBubbling: true,
      align: 'center',
      renderCell: (params) => {
        const onClickDownload = () => {
          const link = document.createElement('a');
          if (params.row.archivo !== undefined) {
            link.href = window.URL.createObjectURL(params.row.archivo);
            link.download = params.row.nombre;
            link.click();
          } else {
            const downloadFileAsync = async (datos) => {
              setOpenBack(true);
              await ordenService.downloadArchivoAWS(datos).then((resp) => {
                link.href = window.URL.createObjectURL(resp.data);
                params.row.archivo = resp.data;
                link.download = params.row.nombre;
                link.click();
              }).catch((e) => {
                console.log('Error al descargar el archivo', e);
                setOpenBack(false);
                setServerError(e.message);
                setOpenSnackError(true);
              });
              setOpenBack(false);
            };
            downloadFileAsync(params.row);
          }
        };
        return (
          <div>
            {idOrden === 0
              ? (
                <Tooltip title="Eliminar">
                  <IconButton
                    className={classes.customHoverFocus}
                    onClick={() => {
                      setOpenEliminar(true);
                      setDeleteFileRow(params.row);
                    }}
                    fontSize="small"
                    color="primary"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                null
              )}
            <Tooltip title="Descargar">
              <IconButton
                className={classes.customHoverFocus}
                onClick={onClickDownload}
                fontSize="small"
                color="primary"
              >
                <Download fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  const onError = (err) => {
    console.log('errores:', err);
    if (err.nombreCliente || err.nombre || err.puesto || err.matricula
      || err.telefonoPrimario) {
      setAcordionGeneral(true);
    }
  };

  const onSubmit = (data) => {
    const handleAsync = async (datos) => {
      console.log('Datos de la orden:', datos);
      setOpenBack(true);
      await ordenService.altaOrden(datos).then((resp) => {
        console.log('Respuesta del servidor OT:', resp);
        if (resp.data != null && resp.data.errorCode === 204) {
          setServerError(resp.data.mensaje);
          setOpenSnackError(true);
        } else {
          setOpenAlta(true);
        }
      }).catch((error) => {
        setOpenBack(false);
        if (error.response !== undefined) {
          setServerError(`${error.response.data.status}: ${error.response.data.message}`);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
        setOpenSnackError(true);
      }).finally(() => {
        setOpenBack(false);
      });
      setOpenBack(false);
    };
    data.id = idOrden;
    data.direccion.id = 0;
    data.idGradoEscolar = isEscuela ? valGradoEscolar.value : 0;
    data.matricula = isEscuela ? data.matricula : '';
    data.puesto = isLaboral ? data.puesto : '';
    data.archivos = rowsFiles.map((auxFile) => {
      if (auxFile.estatus === 'NUEVO') {
        auxFile.id = 0;
      }
      return auxFile;
    });
    data.gradoEscolar = isEscuela ? valGradoEscolar : null;
    data.idOrdenEstatus = estatusSolicitada[0].value;
    data.idEstatusLiga = estatusHiperliga[0].value;
    handleAsync(data);
  };

  return (
    <div>
      <Accordion expanded={acordionGeneral}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={() => setAcordionGeneral(!acordionGeneral)}
          className={classes.summary}
        >
          <Typography variant="h4">Datos Generales</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.accordionDetails}>
          <form
            id={formName}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <OrderGeneral
              register={register}
              folio={folio}
              errors={errors}
              isLaboral={isLaboral}
              isEscuela={isEscuela}
              idOrden={idOrden}
              comboGradoEscolar={comboGradoEscolar}
              valGradoEscolar={valGradoEscolar}
              setValGradoEscolar={setValGradoEscolar}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              setIsEscuela={setIsEscuela}
              setIsLaboral={setIsLaboral}
              getComboGradoEscolar={getComboGradoEscolar}
              rolUsuario={rolUsuario}
              comboEstados={comboEstados}
              comboMunicipios={comboMunicipios}
              getComboMunicipios={getComboMunicipios}
              control={control}
              ordenEstatus={ordenEstatus}
              fechaCitaStr={fechaCitaStr}
              {...props}
            />
          </form>
        </AccordionDetails>
      </Accordion>
      {(idOrden > 0) ? (
        <Accordion expanded={acordionEvaluacion}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            onClick={() => setAcordionEvaluacion(!acordionEvaluacion)}
            className={classes.summary}
          >
            <Typography variant="h4">Consulta de Evaluación</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={classes.accordionDetails}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Estatus de Orden"
                  name="descOrdenEstatus"
                  inputProps={{
                    readOnly: true
                  }}
                  inputRef={register}
                  variant="filled"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Fecha de Solicitud"
                  name="fechaSolicitudStr"
                  inputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register}
                  variant="filled"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Fecha Cita"
                  name="fechaCitaStr"
                  inputProps={{
                    readOnly: true
                  }}
                  inputRef={register}
                  variant="filled"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Fecha de Termino"
                  name="fechaTerminoStr"
                  inputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register}
                  variant="filled"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  label="Comentarios"
                  name="comentarios"
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  minRows={3}
                  inputProps={{
                    readOnly: true
                  }}
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ) : (
        null
      )}
      {(idOrden === 0 || (idOrden > 0 && rowsFiles.length > 0))
        ? (
          <Accordion expanded={acordionArchivos}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              onClick={() => setAcordionArchivos(!acordionArchivos)}
              className={classes.summary}
            >
              <Typography variant="h4">Archivos Adjuntos</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.accordionDetails}>
              {idOrden === 0
                ? (
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item md={9} xs={12}>
                      <DropzoneArea
                        dropzoneClass={classes.dropZoneStyle}
                        acceptedFiles={[
                          'application/pdf',
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          'application/x-zip-compressed',
                          'application/msword',
                          'application/vnd.ms-excel',
                          '.ppt',
                          '.pttx',
                          'text/plain',
                          'image/png',
                          'image/jpeg',
                        ]}
                        onDrop={(file) => {
                          agregaArchivos(file);
                        }}
                        filesLimit={500}
                        dropzoneText={(
                          <div>
                            <div>Arrastre y suelte un archivo.</div>
                            <div style={{ fontWeight: '100', fontFamily: 'sans-serif', fontStyle: 'italic' }}>
                              (Máx 3 MB y con extensiones: .jpg, .png, .pdf,
                              .zip, .doc-x, .xls-x, .ppt-x y .txt)
                            </div>
                          </div>
                        )}
                        maxFileSize={3200000}
                        showPreviewsInDropzone={false}
                        alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
                        showAlerts={['error']}
                        getDropRejectMessage={() => {
                          return 'Solo se aceptan archivos menores a 3 MB y con extensiones: .jpg, .png, .pdf, .zip, .doc-x, .xls-x, .ppt-x y .txt';
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  null
                )}
              {fileSelected.length > 1 ? (
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  style={{ display: rowsFiles.length > 0 ? '' : 'none' }}
                >
                  <Grid item md={12} xs={12} sm={12} align="right">
                    <Tooltip title="Descargar selección">
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<GetAppTwoTone />}
                        onClick={descargaMasiva}
                      >
                        Descargar
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : ''}
              <Grid
                container
                spacing={3}
                justifyContent="center"
                style={{ display: rowsFiles.length > 0 ? '' : 'none' }}
              >
                <Grid item md={12} xs={12} sm={12} align="center">
                  <DataGrid
                    rows={rowsFiles}
                    columns={columnsFiles}
                    autoHeight
                    density="compact"
                    disableColumnFilter
                    disableColumnMenu
                    disableVirtualization
                    hideFooter
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={(selec) => {
                      console.log('La seleccion de archivo:', selec);
                      setFileSelected(selec);
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : (
          null
        )}
      <GeneralBackDrop open={openBack} />
      <ConfirmationDialog
        keepMounted
        open={openEliminar}
        onClose={() => setOpenEliminar(false)}
        confirm={onClickRemove}
        title="Eliminar archivo"
        message="Seguro que desea eliminar el archivo?"
      />
      <ConfirmationDialog
        keepMounted
        open={openAlta}
        confirm={() => {
          setOpenAlta(false);
          setOpenAltaOrden(false);
        }}
        title="Nueva Orden"
        message={`La orden ${folio} fue guardada exitosamente.`}
      />
    </div>
  );
});

AltaClienteOT.propTypes = {
  formName: PropTypes.string,
  comboEstados: PropTypes.array,
  comboMunicipios: PropTypes.array,
  setComboMunicipios: PropTypes.func,
  mapaMunicipios: PropTypes.array,
  setMapaMunicipios: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  idOrden: PropTypes.number,
  setIdOrden: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  comboGradoEscolar: PropTypes.array,
  setComboGradoEscolar: PropTypes.func,
  idCliente: PropTypes.number,
  tipoCliente: PropTypes.string,
  setOpenAltaOrden: PropTypes.func,
  comboOrdenEstatus: PropTypes.array,
  comboHiperliga: PropTypes.array,
  rolUsuario: PropTypes.string
};

export default AltaClienteOT;
