import React, {
  forwardRef, useEffect, useImperativeHandle, useState
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  makeStyles,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  Divider,
  InputAdornment,
  Tooltip,
  IconButton,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Hidden,
  Link,
  Paper,
  Fab,
  Chip,
  Box,
} from '@material-ui/core';
import {
  Delete,
  ExpandMore,
  FileCopyOutlined,
  Send,
  MailOutlined,
  GetAppTwoTone,
} from '@material-ui/icons';
import AmountFormat from 'src/services/amountFormat';
import renderCellExpand from 'src/views/commons/RenderCellExpand';
import { DropzoneArea } from 'material-ui-dropzone';
import genService from 'src/services/generalServices';
import ordenService from 'src/services/ordenService';
import PropTypes from 'prop-types';
import combosService from 'src/services/combosService';
import GeneralBackDrop from 'src/views/commons/GeneralBackDrop';
import { Autocomplete } from '@material-ui/lab';
/* import { format } from 'date-fns'; */
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { DataGrid } from '@material-ui/data-grid';
import { Download } from 'react-feather';
import ConfirmationDialog from 'src/views/commons/ConfirmationDialog';
import NotificacionOrden from './NotificacionOrden';
import OrderGeneral from '../OrderCommons/OrderGeneral';

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    display: 'block'
  },
  dropZoneStyle: {
    minHeight: '100px',
  },
  dropZoneText: {
    fontWeight: '200',
    fontFamily: 'sans-serif'
  },
  rightMargin: {
    marginRight: theme.spacing(1)
  },
  control: {
    padding: theme.spacing(2),
  },
  tooltip: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    boxShadow: 'none'
  },
  verticalCenter: {
    alignItems: 'center',
    display: 'flex'
  },
  summary: {
    backgroundColor: theme.palette.AccordionSummary.background
  }
}));

const AltaOrden = forwardRef(({
  formName,
  comboEstados,
  comboMunicipios,
  setComboMunicipios,
  mapaMunicipios,
  setMapaMunicipios,
  setServerError,
  setOpenSnackError,
  idOrden,
  setIdOrden,
  formData,
  setFormData,
  rowsOrdenes,
  comboClientes,
  comboAnalistas,
  comboOrdenEstatus,
  comboHiperliga,
  comboGradoEscolar,
  setComboGradoEscolar,
  mapaUsuarios,
  setMapaUsuarios,
  comboUsuarios,
  setComboUsuarios,
  setOpenSnackSuccess,
  setServerMessage,
  rolUsuario,
  setOpenAltaOrden
}, ref) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    setValue,
    getValues,
    setError,
    clearErrors
  } = useForm();
  const classes = useStyles();
  const errorReq = 'Campo requerido.';
  const [acordionGeneral, setAcordionGeneral] = useState(true);
  const [acordionEvaluacion, setAcordionEvaluacion] = useState(true);
  const [acordionArchivos, setAcordionArchivos] = useState(true);
  const [acordionLiga, setAcordionLiga] = useState(true);
  const [valComboClientes, setValComboClientes] = useState({});
  const [valComboUsuarios, setValComboUsuarios] = useState({});
  const [valComboAnalistas, setValComboAnalistas] = useState({});
  const [valGradoEscolar, setValGradoEscolar] = useState({});
  const [folio, setFolio] = useState();
  const [rowsFiles, setRowsFiles] = useState([]);
  const [openFechaCita, setOpenFechaCita] = React.useState(false);
  const [fechaCita, setFechaCita] = React.useState(null);
  const [openFechaInicioLiga, setOpenFechaInicioLiga] = React.useState(false);
  const [fechaInicioLiga, setFechaInicioLiga] = React.useState(null);
  const [openFechaFinLiga, setOpenFechaFinLiga] = React.useState(false);
  const [fechaFinLiga, setFechaFinLiga] = React.useState(null);
  const [isLaboral, setIsLaboral] = useState(false);
  const [isEscuela, setIsEscuela] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [llaveLiga, setLlaveLiga] = useState(null);
  const [linkDocs, setLinkDocs] = useState(null);
  const [linkTooltip, setLinkTooltip] = useState('Copiar Liga');
  const [correosLiga, setCorreosLiga] = useState([]);
  const [helperMailsMessage, setHelperMailsMessage] = useState(null);
  const [inputMailValue, setInputMailValue] = useState('');
  const [openEliminarMasiva, setOpenEliminarMasiva] = useState(false);
  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [emailStatusData, setEmailStatusData] = useState({});
  const [deleteFileRow, setDeleteFileRow] = useState(null);
  const [idDireccion, setIdDireccion] = useState(0);
  const [estadoLigaVal, setEstadoLigaVal] = useState(true);
  const [fileSelected, setFileSelected] = useState([]);
  const [reqFechaCita, setReqFechaCita] = useState(false);
  const [reqComentarios, setReqComentarios] = useState(false);
  const [confirmarHiperliga, setConfirmarHiperliga] = useState(false);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const valEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

  /* const addComboMunicipios = (idEstado) => {
    const existMunicipios = mapaMunicipios.filter((item) => item.key === idEstado);
    if (existMunicipios !== undefined && existMunicipios.length > 0) {
      setComboMunicipios(existMunicipios[0].value);
    } else {
      combosService.comboMunicipios(idEstado).then((resp) => {
        setComboMunicipios(resp);
        setMapaMunicipios((newElement) => [...newElement, { key: idEstado, value: resp }]);
      });
    }
  }; */

  const getComboUsuarioCliente = async (idCliente, idUsuario) => {
    setValComboUsuarios({});
    setComboUsuarios([]);
    setOpenBack(true);
    const existUsuarios = mapaUsuarios.filter((item) => item.key === idCliente);
    if (existUsuarios !== undefined && existUsuarios.length > 0) {
      setComboUsuarios(existUsuarios[0].value);
      if (idUsuario !== null) {
        const selectComboUsuarios = existUsuarios[0].value.filter((aux) => (
          aux.value === idUsuario
        ));
        setValComboUsuarios(selectComboUsuarios[0]);
      }
      setLeft(existUsuarios[0].value.filter((aux) => !aux.supervisor));
      setRight(existUsuarios[0].value.filter((aux) => aux.supervisor));
    } else if (idCliente !== undefined && idCliente > 0) {
      const isAdmin = rolUsuario === 'ADMINISTRADOR';
      await combosService.comboUsuariosCliente(idCliente, isAdmin).then((resp) => {
        setComboUsuarios(resp);
        setMapaUsuarios((newElement) => [...newElement, { key: idCliente, value: resp }]);
        if (idUsuario !== null) {
          const selectComboUsuarios = resp.filter((aux) => (
            aux.value === idUsuario
          ));
          setValComboUsuarios(selectComboUsuarios[0]);
        }
        setLeft(resp.filter((aux) => !aux.supervisor));
        setRight(resp.filter((aux) => aux.supervisor));
      }).catch((error) => {
        console.log('Error al llenar el combo de usuarios', error);
        setOpenBack(false);
      });
    }
    setOpenBack(false);
  };

  const getComboGradoEscolar = (idCliente, idGradoEscolar) => {
    if (idCliente !== undefined && idCliente > 0) {
      combosService.comboGradoEscolar(idCliente).then((resp) => {
        setComboGradoEscolar(resp);
        if (idGradoEscolar !== undefined && idGradoEscolar > 0) {
          const selecGrado = resp.filter((aux) => aux.value === idGradoEscolar);
          setValGradoEscolar(selecGrado[0]);
        }
      });
    }
  };

  const resetForm = () => {
    reset({
      id: 0,
      regCliente: '',
      ordenStatus: '',
      comentarios: '',
      importeFacturar: '',
      fechaSolicitudStr: ''
    });
    setValComboClientes({});
    setValComboUsuarios({});
    setValComboAnalistas({});
    setComboUsuarios([]);
    setIsLaboral(false);
    setIsEscuela(false);
    combosService.addComboMunicipios(9, mapaMunicipios,
      setComboMunicipios, setMapaMunicipios);
    setRowsFiles([]);
    setFolio(ordenService.generarFolio());
    const currentDate = new Date();
    const currDateOneMonth = new Date();
    setFechaInicioLiga(currentDate);
    setFechaFinLiga(currDateOneMonth.setMonth(currDateOneMonth.getMonth() + 1));
    setFechaCita(null);
    setCorreosLiga([]);
    setEstadoLigaVal(true);
    setReqComentarios(false);
    setReqFechaCita(false);
  };

  const optionsCliente = comboClientes.map((option) => {
    const firstLetter = option.tipoCliente;
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  useEffect(() => {
    if (formData.id !== undefined && formData.id > 0) {
      setIdOrden(formData.id);
      setIdDireccion(formData.direccion.id);
      setFolio(formData.folio);
      const selectComboClientes = optionsCliente.filter((aux) => (
        aux.value === formData.idCliente
      ));
      setValComboClientes(selectComboClientes[0]);
      if (selectComboClientes[0].tipoCliente === 'Escuela') {
        setIsEscuela(true);
      } else if (selectComboClientes[0].tipoCliente === 'Laboral') {
        setIsLaboral(true);
      }
      setFechaCita(formData.fechaCita);
      console.log('Fecha de cita recien cargada', fechaCita);
      const selectComboAnalistas = comboAnalistas.filter((aux) => (
        formData.idAnalista === aux.value
      ));
      if (selectComboAnalistas.length > 0) {
        setValComboAnalistas(selectComboAnalistas[0]);
      }
      getComboGradoEscolar(formData.idCliente, formData.idGradoEscolar);
      if (formData.archivos !== null && formData.archivos !== undefined
        && formData.archivos.length > 0) {
        setRowsFiles(formData.archivos);
        setAcordionArchivos(true);
      } else {
        setAcordionArchivos(false);
      }
      if (formData.listCorreosLiga !== null && formData.listCorreosLiga !== undefined) {
        setCorreosLiga(formData.listCorreosLiga);
      }
      setFechaInicioLiga(formData.fechaInicioLiga);
      setFechaFinLiga(formData.fechaFinLiga);
      setEstadoLigaVal(formData.estadoLiga);
      setLinkDocs(`${window.location.origin}/cargaDocumentos?key=${formData.llaveLiga}`);
      setLlaveLiga(formData.llaveLiga);
      const llenaComboUsuarios = async (idCliente, idUsuario) => {
        await getComboUsuarioCliente(idCliente, idUsuario);
      };
      llenaComboUsuarios(formData.idCliente, formData.idUsuarioCliente);
      const orderStatus = comboOrdenEstatus.filter(
        (auxStatus) => auxStatus.value === formData.idOrdenEstatus
      );
      if (orderStatus !== undefined && orderStatus !== null
      && orderStatus[0].label === 'CITA') {
        setReqFechaCita(true);
      } else if ((orderStatus !== undefined && orderStatus !== null && orderStatus[0].label === 'SUSPENDIDO')) {
        setReqComentarios(true);
      }
      const nombreCliente = comboClientes.filter(
        (auxCliente) => auxCliente.value === formData.idCliente
      );
      console.log('Valor del Combo de cliente', nombreCliente);
      setEmailStatusData({
        nombreCliente: nombreCliente[0].label,
        folio: formData.folio,
        descOrdenEstatus: orderStatus[0].label,
        nombre: formData.nombre,
        fechaCita: formData.fechaCita,
        comentarios: formData.comentarios
      });
      console.log('Email status data:', formData.comentarios);
    } else {
      setIdOrden(0);
      setFolio(ordenService.generarFolio());
      setFormData({});
      setComboGradoEscolar([]);
      const currentDate = new Date();
      const currDateOneMonth = new Date();
      setFechaInicioLiga(currentDate);
      setFechaFinLiga(currDateOneMonth.setMonth(currDateOneMonth.getMonth() + 1));
    }
    if (formData.direccion?.idEstado !== undefined
      && formData.direccion?.idEstado !== null
      && formData.direccion.idEstado > 0) {
      combosService.addComboMunicipios(formData.direccion.idEstado,
        mapaMunicipios, setComboMunicipios, setMapaMunicipios);
    } else {
      combosService.addComboMunicipios(9, mapaMunicipios,
        setComboMunicipios, setMapaMunicipios);
    }
    reset(formData);
    setFormData({});
    console.log('Datos de la orden a editar', formData);
  }, []);

  useImperativeHandle(ref, () => ({
    resetOrdenForm: () => {
      resetForm();
    },
    sendOrderStatusEmail: () => {
      setOpenNotificacion(true);
    }
  }));

  const getComboMunicipios = (newValue) => {
    setComboMunicipios([]);
    console.log(newValue);
    if (newValue !== null) {
      setValue('idMunicipio', '');
      combosService.addComboMunicipios(newValue, mapaMunicipios,
        setComboMunicipios, setMapaMunicipios);
    }
  };

  const onError = (err) => {
    console.log('errores:', err);
    if (err.nombreCliente || err.nombre || err.puesto || err.matricula
      || err.telefonoPrimario) {
      setAcordionGeneral(true);
    }
    if (err.idOrdenEstatus) {
      setAcordionEvaluacion(true);
    }
  };

  const agregaArchivos = (fileList) => {
    console.log('Files:', fileList);
    let filesList = [];
    const uploadFileToS3 = async (file) => {
      setOpenBack(true);
      console.log(file);
      if (filesList.length === 0) {
        filesList = rowsFiles.slice();
      }
      const existingFile = filesList.findIndex((auxFile) => auxFile.nombre === file.name);
      let nombreArchivo = file.name;
      const nombreSinExtension = nombreArchivo.substring(0, nombreArchivo.lastIndexOf('.'));
      if (nombreSinExtension.length > 50) {
        const extension = nombreArchivo.substring(nombreArchivo.lastIndexOf('.'), nombreArchivo.length);
        const nombreCorto = nombreSinExtension.substring(0, 50);
        console.log('Longitud del nombre', nombreArchivo.length);
        console.log('Extension del archivo', extension);
        console.log('nombreCorto', nombreCorto + extension);
        nombreArchivo = nombreCorto.trim() + extension;
        setOpenSnackError(true);
        setServerError('El nombre de alguno de sus archivos fue reducido a 50 caracteres.');
      }
      let uploadedFile;
      if (existingFile < 0) {
        const itemFile = {
          id: `${folio}_${nombreArchivo}`,
          nombre: nombreArchivo,
          size: file.size,
          fileBucketName: `GENERAL_${folio}_${nombreArchivo}`,
          comentarios: '',
          estatus: 'NUEVO',
          tipoArchivo: 'GENERAL',
          archivo: file,
          enServidor: false
        };
        filesList.push(itemFile);
        uploadedFile = itemFile;
      } else {
        filesList[existingFile].archivo = file;
        filesList[existingFile].size = file.size;
        if (filesList[existingFile].estatus !== 'NUEVO') {
          filesList[existingFile].estatus = 'ACTUALIZADO';
        }
        uploadedFile = filesList[existingFile];
      }
      console.log('Datos del archivo', uploadedFile);
      /* await ordenService.uploadArchivoAWS(uploadedFile, file); */
      setOpenBack(false);
    };

    fileList.forEach((file) => {
      uploadFileToS3(file);
    });
    setRowsFiles(filesList);
  };

  /* const onClickRemove = () => {
    const listFiles = rowsFiles.filter((row) => row.id !== deleteFileRow.id);
    console.log('El archivo a borrar:', deleteFileRow);
    if (deleteFileRow.enServidor) {
      const deleteFile = rowsFiles.filter((row) => row.id === deleteFileRow.id);
      ordenService.deleteOrderFile(deleteFile[0]).catch((error) => console.log(error));
    }
    setRowsFiles(listFiles);
    setOpenEliminar(false);
    setDeleteFileRow(null);
  }; */

  const deleteMasiveFiles = async () => {
    setOpenBack(true);
    setOpenEliminarMasiva(false);
    let selectedRows = [];
    let nonSelectedRows = [];
    console.log(deleteFileRow);
    console.log(rowsFiles);
    if (deleteFileRow !== null) {
      selectedRows = rowsFiles.filter((row) => (
        row.id === deleteFileRow.id && row.enServidor
      ));
      nonSelectedRows = rowsFiles.filter((row) => row.id !== deleteFileRow.id);
    } else {
      selectedRows = rowsFiles.filter(({ id, enServidor }) => (
        fileSelected.includes(id) && enServidor
      ));
      nonSelectedRows = rowsFiles.filter(({ id }) => !fileSelected.includes(id));
    }
    if (selectedRows.length > 0) {
      await ordenService.deleteOrderFile(selectedRows).then((resp) => {
        console.log('Ordenes filtradas:', nonSelectedRows);
        setRowsFiles(nonSelectedRows);
        setOpenSnackSuccess(true);
        setServerMessage(resp.data.mensaje);
      }).catch((error) => {
        setOpenSnackError(true);
        if (error.response) {
          setServerError(error.response.data.message);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
      });
    } else {
      setRowsFiles(nonSelectedRows);
    }
    setDeleteFileRow(null);
    setOpenBack(false);
  };

  const onClickEnviarHiperliga = async () => {
    const emailData = {};
    emailData.nombreCliente = getValues('nombre');
    emailData.listCorreosLiga = correosLiga;
    emailData.folio = folio;
    emailData.llaveLiga = linkDocs;
    console.log('Datos de email:', emailData);
    setOpenBack(true);
    setConfirmarHiperliga(false);
    await ordenService.enviarEmailLiga(emailData).then(() => {
      setServerMessage(`El correo con la liga para la orden ${folio} fue enviado correctamente.`);
      setOpenSnackSuccess(true);
    }).catch((error) => {
      console.log('Error al enviar correo de liga:', error);
      setOpenSnackError(true);
      setServerError(`Error al enviar correo de liga para la orden ${folio}`);
      setConfirmarHiperliga(false);
      setOpenBack(false);
    });
    setOpenBack(false);
  };

  const onAddCorreos = (newVal) => {
    const lastVal = newVal.trim();
    setHelperMailsMessage(null);
    setInputMailValue('');
    if (newVal !== undefined && valEmail.test(newVal.trim())) {
      const filtraCorreo = correosLiga.filter((mail) => lastVal === mail);
      if (filtraCorreo.length > 0) {
        setHelperMailsMessage('Este correo ya fue ingresado anteriormente.');
        setInputMailValue(newVal.trim());
      } else {
        correosLiga.push(newVal.trim());
      }
    } else if (lastVal !== undefined && lastVal !== '') {
      setHelperMailsMessage('Verificar el formato del correo ingresado.');
      setInputMailValue(newVal.trim());
    }
  };

  const onSubmit = (data) => {
    console.log('Datos de la orden:', data);
    console.log('el combo del grado escolar antes', comboGradoEscolar);
    const handleAsync = async (datos) => {
      setOpenBack(true);
      await ordenService.altaOrden(datos).then((resp) => {
        console.log('Respuesta del servidor:', resp);
        if (resp.data != null && resp.data.errorCode === 204) {
          setServerError(resp.data.mensaje);
          setOpenSnackError(true);
        } else {
          console.log('Rows a modificar:', rowsOrdenes);
          if (idOrden > 0 && rowsOrdenes !== undefined && rowsOrdenes !== null) {
            const modOrden = rowsOrdenes.find((row) => row.id === idOrden);
            if (modOrden !== undefined && modOrden !== null) {
              Object.assign(modOrden, datos);
              modOrden.descOrdenEstatus = resp.data.strOrdenEstatus;
            }
          }
          // Se agregó un nuevo grado escolar, se debe actualizar el combo.
          if (data.gradoEscolar && data.gradoEscolar.value === 0) {
            data.gradoEscolar.value = resp.data.gradoEscolar.value;
            console.log('el combo del grado escolar', comboGradoEscolar);
          }
          console.log('Datos de la orden guardada:', resp.data);
          /* setOpen(true); */
          setOpenSnackSuccess(true);
          setOpenAltaOrden(false);
          setServerMessage(`La orden ${folio} fue guardada exitosamente.`);
          setIdOrden(resp.data.id);
          setIdDireccion(resp.data.direccion.id);
          setLinkDocs(`${window.location.origin}/cargaDocumentos?key=${resp.data.llaveLiga}`);
          setLlaveLiga(resp.data.llaveLiga);
          setValue('fechaTermino', resp.data.fechaTermino);
          setValue('fechaFinalizacionLigaStr', resp.data.fechaFinalizacionLigaStr);
          setRowsFiles(resp.data.archivos);
          emailStatusData.descOrdenEstatus = resp.data.strOrdenEstatus;
          emailStatusData.nombre = resp.data.nombre;
          emailStatusData.fechaCita = resp.data.fechaCita;
          emailStatusData.comentarios = resp.data.comentarios;
        }
      }).catch((error) => {
        if (error.response !== undefined) {
          setServerError(`${error.response.data.status}: ${error.response.data.message}`);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
        setOpenSnackError(true);
      }).finally(() => {
        setOpenBack(false);
      });
      setOpenBack(false);
    };
    data.id = idOrden;
    if (idOrden === 0) {
      data.direccion.id = 0;
    }
    data.idUsuarioCliente = valComboUsuarios.value;
    data.idCliente = valComboClientes.value;
    data.idGradoEscolar = isEscuela ? valGradoEscolar.value : 0;
    data.matricula = isEscuela ? data.matricula : '';
    data.puesto = isLaboral ? data.puesto : '';
    data.archivos = rowsFiles.map((auxFile) => {
      if (auxFile.estatus === 'NUEVO') {
        auxFile.id = 0;
      }
      return auxFile;
    });
    data.fechaInicioLiga = fechaInicioLiga;
    data.fechaFinLiga = fechaFinLiga;
    data.fechaCita = fechaCita;
    data.listCorreosLiga = correosLiga;
    data.idAnalista = valComboAnalistas?.value;
    data.gradoEscolar = isEscuela ? valGradoEscolar : null;
    data.direccion.id = idDireccion;
    data.estadoLiga = estadoLigaVal;
    data.llaveLiga = llaveLiga;
    const estatusSelec = comboOrdenEstatus.filter(
      (auxEstatus) => data.idOrdenEstatus === auxEstatus.value
    );
    data.strOrdenEstatus = estatusSelec[0].label;
    data.descEstatusLiga = comboHiperliga.filter(
      (edoLink) => data.idEstatusLiga === edoLink.value
    )[0]?.label;
    handleAsync(data);
  };

  const descargaMasiva = async () => {
    setOpenBack(true);
    const selectedRows = rowsFiles.filter(({ id }) => fileSelected.includes(id));
    const files = selectedRows.filter((auxFile) => auxFile.archivo !== undefined)
      .map((filteredFile) => filteredFile.archivo);
    const filesObject = selectedRows.filter((auxFile) => auxFile.estatus !== 'NUEVO');
    await ordenService.descargarZip(filesObject, files, folio).then((response) => {
      console.log('Respuesta descarga zip', response);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response.data);
      link.download = `${folio}.zip`;
      link.click();
    }).catch((e) => {
      if (e.response) {
        e.response.data.text().then((errorResp) => {
          const finalError = JSON.parse(errorResp);
          console.log('error:', finalError);
          setServerError(finalError.message);
        });
      } else {
        setServerError(`${e.message}: No fue posible conectar con el servidor`);
      }
      setOpenSnackError(true);
    });
    setOpenBack(false);
  };

  const columnsFiles = [
    {
      field: 'tipoArchivo',
      headerName: 'Tipo de Archivo',
      minWidth: 120,
      flex: 1,
      hideSortIcons: true
    },
    {
      field: 'nombre',
      headerName: 'Nombre de Archivo',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'comentarios',
      headerName: 'Comentarios',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'acciones',
      headerName: 'Acciones',
      maxWidth: 60,
      minWidth: 60,
      sortable: false,
      disableClickEventBubbling: true,
      align: 'center',
      renderCell: (params) => {
        const onClickDownload = () => {
          const link = document.createElement('a');
          if (params.row.archivo !== undefined) {
            link.href = window.URL.createObjectURL(params.row.archivo);
            link.download = params.row.nombre;
            link.click();
          } else {
            const downloadFileAsync = async (datos) => {
              setOpenBack(true);
              await ordenService.downloadArchivoAWS(datos).then((resp) => {
                link.href = window.URL.createObjectURL(resp.data);
                console.log('Respuesta archivo:', resp.headers);
                // params.row.archivo = resp.data;
                link.download = params.row.nombre;
                link.click();
              }).catch((e) => {
                console.log('Error al descargar el archivo', e);
                setOpenBack(false);
                setServerError(e.message);
                setOpenSnackError(true);
              });
              setOpenBack(false);
            };
            downloadFileAsync(params.row);
          }
        };
        return (
          <div>
            <Tooltip title="Eliminar">
              <IconButton
                className={classes.customHoverFocus}
                onClick={() => {
                  setOpenEliminarMasiva(true);
                  setDeleteFileRow(params.row);
                }}
                fontSize="small"
                color="primary"
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Descargar">
              <IconButton
                className={classes.customHoverFocus}
                onClick={onClickDownload}
                fontSize="small"
                color="primary"
              >
                <Download fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <Accordion expanded={acordionGeneral}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={() => setAcordionGeneral(!acordionGeneral)}
          className={classes.summary}
        >
          <Typography variant="h4">Datos Generales</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.accordionDetails}>
          <form
            id={formName}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <OrderGeneral
              register={register}
              folio={folio}
              errors={errors}
              isLaboral={isLaboral}
              isEscuela={isEscuela}
              idOrden={idOrden}
              comboGradoEscolar={comboGradoEscolar}
              valGradoEscolar={valGradoEscolar}
              setValGradoEscolar={setValGradoEscolar}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              optionsCliente={optionsCliente}
              valComboClientes={valComboClientes}
              setValComboClientes={setValComboClientes}
              setIsEscuela={setIsEscuela}
              setIsLaboral={setIsLaboral}
              getComboGradoEscolar={getComboGradoEscolar}
              getComboUsuarioCliente={getComboUsuarioCliente}
              comboUsuarios={comboUsuarios}
              valComboUsuarios={valComboUsuarios}
              setValComboUsuarios={setValComboUsuarios}
              rolUsuario={rolUsuario}
              comboEstados={comboEstados}
              comboMunicipios={comboMunicipios}
              getComboMunicipios={getComboMunicipios}
              control={control}
            />
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={acordionEvaluacion}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={() => setAcordionEvaluacion(!acordionEvaluacion)}
          className={classes.summary}
        >
          <Typography variant="h4">Evaluación</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.accordionDetails}>
          <form
            id={formName}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel htmlFor="ordenEstatus-select">
                    {
                      errors.idOrdenEstatus
                        ? <div style={{ color: '#f44336' }}>Seleccione un Estatus *</div>
                        : <div>Seleccione un Estatus *</div>
                    }
                  </InputLabel>
                  <Controller
                    control={control}
                    name="idOrdenEstatus"
                    rules={{ required: { value: true, message: errorReq } }}
                    error={errors.idOrdenEstatus}
                    defaultValue={comboOrdenEstatus[0].value}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    render={({ onChange, value }) => (
                      <Select
                        id="OrdenEstatus-select"
                        label="Seleccione un Estatus"
                        value={value}
                        onChange={(evento, newValue) => {
                          onChange(newValue.props.value);
                          setReqFechaCita(false);
                          setReqComentarios(false);
                          clearErrors(['comentarios', 'fechaCita']);
                          if (newValue.props.children === 'CITA') {
                            setReqFechaCita(true);
                          } else if (newValue.props.children === 'SUSPENDIDO') {
                            setReqComentarios(true);
                          }
                        }}
                      >
                        {comboOrdenEstatus.map((status) => (
                          <MenuItem
                            key={status.value}
                            value={status.value}
                          >
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {
                    errors.idOrdenEstatus
                    && (
                      <FormHelperText style={{ color: '#f44336' }}>
                        {errors.idOrdenEstatus?.message}
                      </FormHelperText>
                    )
                  }
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Fecha de Solicitud"
                  name="fechaSolicitudStr"
                  inputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register}
                  variant="filled"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <MuiPickersUtilsProvider
                  locale={esLocale}
                  utils={DateFnsUtils}
                >
                  <KeyboardDateTimePicker
                    name="fechaCita"
                    open={openFechaCita}
                    onOpen={() => setOpenFechaCita(true)}
                    onClose={() => setOpenFechaCita(false)}
                    onChange={setFechaCita}
                    format="dd/MM/yyyy HH:mm:ss"
                    margin="none"
                    label={reqFechaCita ? 'Fecha de Cita *' : 'Fecha de Cita'}
                    value={fechaCita}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    fullWidth
                    error={errors.fechaCita}
                    helperText={errors.fechaCita?.message}
                    inputRef={register({
                      required: { value: reqFechaCita, message: 'Debe ingresar una fecha de cita.' },
                    })}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Fecha de Termino"
                  name="fechaTermino"
                  inputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register}
                  variant="filled"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            {rolUsuario === 'ADMINISTRADOR'
              ? (
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      id="combo-box-analista"
                      options={comboAnalistas}
                      getOptionLabel={(option) => (option.label ? `${option.label} - (${option.usuario})` : '')}
                      renderOption={(option) => `${option.label} - (${option.usuario})`}
                      fullWidth
                      value={valComboAnalistas}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="regAnalista"
                          inputRef={register}
                          label="Seleccione un Analista"
                          variant="outlined"
                          size="small"
                          error={errors.regAnalista}
                          helperText={errors.regAnalista?.message}
                          onInput={genService.toUpperCase}
                        />
                      )}
                      onChange={(event, newValue) => {
                        errors.regAnalista = null;
                        setValComboAnalistas(newValue);
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      label="Importe a Facturar"
                      name="importeFacturar"
                      inputRef={register}
                      defaultValue={formData.importeFacturar}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        inputComponent: AmountFormat,
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      error={errors.importeFacturar}
                      helperText={errors.importeFacturar?.message}
                    />
                  </Grid>
                </Grid>
              ) : (
                null
              )}
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  label={reqComentarios ? 'Comentarios *' : 'Comentarios'}
                  name="comentarios"
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  minRows={3}
                  onInput={genService.toUpperCase}
                  error={errors.comentarios}
                  helperText={errors.comentarios?.message}
                  inputRef={register({
                    required: { value: reqComentarios, message: 'Para guardar la orden es necesario agregar un comentario.' },
                  })}
                />
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      {rolUsuario === 'ADMINISTRADOR'
        ? (
          <Accordion expanded={acordionLiga}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              onClick={() => setAcordionLiga(!acordionLiga)}
              className={classes.summary}
            >
              <Typography variant="h4">Link Hiperliga</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.accordionDetails}>
              <form
                id={formName}
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                { idOrden > 0
                  ? (
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item>
                        <Paper className={classes.control}>
                          <Link href={linkDocs} target="_blank">
                            {linkDocs}
                          </Link>
                        </Paper>
                      </Grid>
                      <Grid item className={classes.verticalCenter}>
                        <Tooltip
                          title={linkTooltip}
                          className={classes.tooltip}
                          leaveDelay="500"
                          arrow
                          onClose={() => setLinkTooltip('Copiar Liga')}
                        >
                          <Fab
                            aria-label="edit"
                            size="small"
                            onClick={() => {
                              navigator.clipboard.writeText(linkDocs);
                              setLinkTooltip('Link Copiado!');
                            }}
                            color="transparent"
                          >
                            <FileCopyOutlined />
                          </Fab>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    null
                  )}
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Estado Liga</FormLabel>
                      <RadioGroup
                        row
                        value={estadoLigaVal}
                        onChange={(e) => {
                          if (e.currentTarget.value === 'true') {
                            setEstadoLigaVal(true);
                          } else {
                            setEstadoLigaVal(false);
                          }
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Activo"
                          value
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Inactivo"
                          value={false}
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        name="fechaInicioLiga"
                        open={openFechaInicioLiga}
                        onOpen={() => setOpenFechaInicioLiga(true)}
                        onClose={() => setOpenFechaInicioLiga(false)}
                        format="dd/MM/yyyy"
                        margin="none"
                        label="Vigencia Inicio"
                        value={fechaInicioLiga}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        fullWidth
                        onChange={(newDate) => {
                          setFechaInicioLiga(newDate);
                          if (new Date(newDate) > new Date(fechaFinLiga)) {
                            setError('fechaInicioLiga', { type: 'custom', message: 'La fecha de Inicio no puede ser mayor a la fecha de Fin.' });
                          } else {
                            clearErrors();
                          }
                        }}
                        error={errors.fechaInicioLiga}
                        helperText={errors.fechaInicioLiga?.message}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        name="fechaFinLiga"
                        open={openFechaFinLiga}
                        onOpen={() => setOpenFechaFinLiga(true)}
                        onClose={() => setOpenFechaFinLiga(false)}
                        format="dd/MM/yyyy"
                        margin="none"
                        label="Vigencia Fin"
                        value={fechaFinLiga}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        fullWidth
                        onChange={(newDate) => {
                          setFechaFinLiga(newDate);
                          if (new Date(newDate) < new Date(fechaInicioLiga)) {
                            setError('fechaFinLiga', { type: 'custom', message: 'La fecha de Fin no puede ser menor a la fecha de Inicio.' });
                          } else {
                            clearErrors();
                          }
                        }}
                        error={errors.fechaFinLiga}
                        helperText={errors.fechaFinLiga?.message}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={10} xs={9}>
                    <Autocomplete
                      multiple
                      freeSolo
                      id="tags-outlined"
                      options={[]}
                      value={correosLiga}
                      inputValue={inputMailValue}
                      onInputChange={(e, newValue, reason) => {
                        if (reason === 'input') {
                          setInputMailValue(newValue);
                        }
                      }}
                      onChange={() => {
                        onAddCorreos(inputMailValue);
                      }}
                      renderTags={(value, getTagProps) => (
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            color="primary"
                            icon={<MailOutlined />}
                            clickable
                            onClick={() => {
                              if (inputMailValue !== '' && valEmail.test(inputMailValue)) {
                                const addValue = correosLiga.push(inputMailValue);
                                setCorreosLiga(addValue);
                                setInputMailValue('');
                              }
                              const tempArray = correosLiga.filter((item) => (
                                item !== correosLiga[index]
                              ));
                              setInputMailValue(correosLiga[index]);
                              setCorreosLiga(tempArray);
                            }}
                            onDelete={() => {
                              const newCorreosList = correosLiga.filter((mail) => mail !== option);
                              setCorreosLiga(newCorreosList);
                            }}
                          />
                        )))}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Correos para envío de liga"
                          placeholder="Ingrese un correo..."
                          error={helperMailsMessage !== null}
                          helperText={helperMailsMessage}
                          onInput={genService.toLowerCase}
                          onKeyDown={(e) => {
                            if (e.keyCode === 32) {
                              onAddCorreos(inputMailValue);
                            }
                          }}
                          onBlur={() => {
                            onAddCorreos(inputMailValue);
                          }}
                          onKeyUp={(e) => {
                            if (e.keyCode === 32 && inputMailValue === ' ') {
                              setInputMailValue('');
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {idOrden > 0 && correosLiga?.length > 0
                    ? (
                      <Grid item md={2} xs={3} className={classes.verticalCenter}>
                        <Hidden smDown>
                          <Tooltip title="Enviar Correo Liga">
                            <Button
                              variant="contained"
                              color="primary"
                              endIcon={<Send />}
                              onClick={() => setConfirmarHiperliga(true)}
                            >
                              ENVIAR
                            </Button>
                          </Tooltip>
                        </Hidden>
                        <Hidden mdUp>
                          <Tooltip title="Enviar Correos">
                            <Button variant="contained" color="primary" endIcon={<Send />} />
                          </Tooltip>
                        </Hidden>
                      </Grid>
                    ) : (
                      null
                    )}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      control={control}
                      variant="outlined"
                      size="small"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel htmlFor="linkEstatus-select">
                        {
                          errors.idEstatusLiga
                            ? <div style={{ color: '#f44336' }}>Seleccione un Estatus *</div>
                            : <div>Seleccione un Estatus *</div>
                        }
                      </InputLabel>
                      <Controller
                        control={control}
                        name="idEstatusLiga"
                        rules={{ required: { value: true, message: errorReq } }}
                        error={errors.idEstatusLiga}
                        defaultValue={comboHiperliga[0].value}
                        onChange={([selected]) => {
                          return { value: selected };
                        }}
                        render={({ onChange, value }) => (
                          <Select
                            id="LinkEstatus-select"
                            label="Estatus de Hipeliga"
                            value={value}
                            onChange={(evento, newValue) => {
                              onChange(newValue.props.value);
                            }}
                          >
                            {comboHiperliga.map((status) => (
                              <MenuItem
                                key={status.value}
                                value={status.value}
                              >
                                {status.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {
                        errors.idEstatusLiga
                        && (
                          <FormHelperText style={{ color: '#f44336' }}>
                            {errors.idEstatusLiga?.message}
                          </FormHelperText>
                        )
                      }
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      label="Fecha de Finalización"
                      name="fechaFinalizacionLigaStr"
                      inputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register}
                      variant="filled"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
        ) : (
          null
        )}
      {rolUsuario === 'ADMINISTRADOR'
        ? (
          <Accordion expanded={acordionArchivos}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              onClick={() => setAcordionArchivos(!acordionArchivos)}
              className={classes.summary}
            >
              <Typography variant="h4">Archivos Adjuntos</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.accordionDetails}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item md={9} xs={12}>
                  <DropzoneArea
                    dropzoneClass={classes.dropZoneStyle}
                    acceptedFiles={[
                      'application/pdf',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      'application/x-zip-compressed',
                      'application/msword',
                      'application/vnd.ms-excel',
                      '.ppt',
                      '.pttx',
                      'text/plain',
                      'image/png',
                      'image/jpeg',
                    ]}
                    onDrop={(file) => {
                      agregaArchivos(file);
                    }}
                    filesLimit={500}
                    dropzoneText={(
                      <div>
                        <div>Arrastre y suelte un archivo.</div>
                        <div style={{ fontWeight: '100', fontFamily: 'sans-serif', fontStyle: 'italic' }}>
                          (Máx 3 MB y con extensiones: .jpg, .png, .pdf,
                          .zip, .doc-x, .xls-x, .ppt-x y .txt)
                        </div>
                      </div>
                    )}
                    maxFileSize={3200000}
                    showPreviewsInDropzone={false}
                    alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
                    showAlerts={['error']}
                    getDropRejectMessage={() => {
                      return 'Solo se aceptan archivos menores a 3 MB y con extensiones: .jpg, .png, .pdf, .zip, .doc-x, .xls-x, .ppt-x y .txt';
                    }}
                  />
                </Grid>
              </Grid>
              {fileSelected.length > 1 ? (
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  style={{ display: rowsFiles.length > 0 ? '' : 'none' }}
                >
                  <Grid item md={12} xs={12} sm={12} align="right">
                    <Box display="flex" justifyContent="flex-end">
                      <Tooltip title="Eliminar selección">
                        <Button
                          className={classes.rightMargin}
                          variant="contained"
                          color="primary"
                          endIcon={<Delete />}
                          onClick={() => setOpenEliminarMasiva(true)}
                        >
                          ELIMINAR
                        </Button>
                      </Tooltip>
                      <Tooltip title="Descargar selección">
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<GetAppTwoTone />}
                          onClick={descargaMasiva}
                        >
                          Descargar
                        </Button>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              ) : ''}
              <Grid
                container
                spacing={3}
                justifyContent="center"
                style={{ display: rowsFiles.length > 0 ? '' : 'none' }}
              >
                <Grid item md={12} xs={12} sm={12} align="center">
                  <DataGrid
                    rows={rowsFiles}
                    columns={columnsFiles}
                    autoHeight
                    density="compact"
                    disableColumnFilter
                    disableColumnMenu
                    disableVirtualization
                    hideFooter
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={(selec) => {
                      console.log('La seleccion de archivo:', selec);
                      setFileSelected(selec);
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : (
          null
        )}
      <GeneralBackDrop open={openBack} />
      <ConfirmationDialog
        keepMounted
        open={openEliminarMasiva}
        onClose={() => {
          setOpenEliminarMasiva(false);
          setDeleteFileRow(null);
        }}
        confirm={deleteMasiveFiles}
        title="Eliminar archivos"
        message={deleteFileRow === null ? `Seguro que desea eliminar los ${fileSelected.length} archivos seleccionados?` : 'Seguro que desea eliminar el archivo?'}
      />
      <ConfirmationDialog
        keepMounted
        open={confirmarHiperliga}
        onClose={() => setConfirmarHiperliga(false)}
        confirm={onClickEnviarHiperliga}
        title="Enviar Hiperliga"
        message="Seguro que desea enviar el correo con la Hiperliga?"
      />
      <NotificacionOrden
        setOpenNotificacion={setOpenNotificacion}
        openNotificacion={openNotificacion}
        left={left}
        setLeft={setLeft}
        right={right}
        setRight={setRight}
        setOpenSnackError={setOpenSnackError}
        setOpenSnackSuccess={setOpenSnackSuccess}
        setServerMessage={setServerMessage}
        setServerError={setServerError}
        emailData={emailStatusData}
      />
    </div>
  );
});

AltaOrden.propTypes = {
  formName: PropTypes.string,
  comboEstados: PropTypes.array,
  comboMunicipios: PropTypes.array,
  setComboMunicipios: PropTypes.func,
  mapaMunicipios: PropTypes.array,
  setMapaMunicipios: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  idOrden: PropTypes.number,
  setIdOrden: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  rowsOrdenes: PropTypes.array,
  comboClientes: PropTypes.array,
  comboAnalistas: PropTypes.array,
  comboOrdenEstatus: PropTypes.array,
  comboHiperliga: PropTypes.array,
  comboGradoEscolar: PropTypes.array,
  setComboGradoEscolar: PropTypes.func,
  mapaUsuarios: PropTypes.array,
  setMapaUsuarios: PropTypes.func,
  comboUsuarios: PropTypes.array,
  setComboUsuarios: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setServerMessage: PropTypes.func,
  rolUsuario: PropTypes.string,
  setOpenAltaOrden: PropTypes.func
};

export default AltaOrden;
