import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  makeStyles,
  Box,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import moment from 'moment';
import combosService from 'src/services/combosService';
import Page from 'src/components/Page';
import PropTypes from 'prop-types';
import DataTable from 'src/views/commons/DataTable';
import DialogForms from 'src/views/commons/DialogForms';
import ordenService from 'src/services/ordenService';
import ChipEstatusTable from 'src/views/commons/ChipEstatusTable';
import renderCellExpand from 'src/views/commons/RenderCellExpand';
import OTClienteToolBar from './OTClienteToolBar';
import AltaClienteOT from './AltaClienteOT';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  customHoverFocus: {
    '&:hover': { color: '#fff', backgroundColor: '#4caf50' }
  }
}));

const ClienteOT = ({
  setComboOrdenEstatus,
  comboOrdenEstatus,
  setComboHiperliga,
  comboHiperliga,
  setOpenBackDrop,
  setServerError,
  setOpenSnackError,
  rolUsuario,
  ...props
}) => {
  const classes = useStyles();

  const [openAltaOrden, setOpenAltaOrden] = useState(false);
  const [rowsOrdenes, setRowsOrdenes] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [formData, setFormData] = useState({});
  const [idOrden, setIdOrden] = useState(0);
  const [mapaUsuarios, setMapaUsuarios] = useState([]);
  const [comboGradoEscolar, setComboGradoEscolar] = useState([]);
  const formName = 'formOrden';
  const ordenRef = useRef();

  const resetOrderRef = () => {
    if (idOrden === 0) {
      ordenRef.current.resetOrdenForm();
    } else {
      setOpenAltaOrden(false);
    }
  };

  const onClickEdit = (rowId) => {
    const modifyOrder = async (orderId) => {
      setOpenBackDrop(true);
      await ordenService.getOrdenById(orderId).then((resp) => {
        if (resp.data != null && resp.data.errorCode === 204) {
          setServerError(resp.data.mensaje);
          setOpenSnackError(true);
        } else {
          console.log('Orden para cliente:', resp.data);
          setFormData(resp.data);
          setOpenAltaOrden(true);
        }
      }).catch((err) => {
        console.log(err);
        setServerError(err.message);
        setOpenSnackError(true);
      }).finally(() => {
        setOpenBackDrop(false);
      });
    };
    modifyOrder(rowId);
  };

  useEffect(() => {
    const getCombosAsync = async () => {
      setOpenBackDrop(true);
      if (comboOrdenEstatus === undefined || comboOrdenEstatus.length === 0) {
        combosService.comboOrdenEstatus().then((resp) => {
          setComboOrdenEstatus(resp);
        });
      }
      if (comboHiperliga === undefined || comboHiperliga === null || comboHiperliga.length === 0) {
        combosService.comboLinkEstatusActivos().then((resp) => {
          setComboHiperliga(resp);
        });
      }
      const idDashOrden = window.localStorage.getItem('idOrden');
      if (idDashOrden) {
        window.localStorage.removeItem('idOrden');
        onClickEdit(idDashOrden);
      }
      setOpenBackDrop(false);
    };
    getCombosAsync();
  }, []);

  const columns = [
    {
      field: 'folio',
      headerName: 'Folio',
      maxWidth: 100,
      minWidth: 100,
      headerClassName: 'header-datagrid',
      renderCell: renderCellExpand,
    },
    {
      field: 'nombreUsuario',
      headerName: 'Usuario',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'nombre',
      headerName: 'Investigado',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'telefonoPrimario',
      headerName: 'Teléfono',
      minWidth: 100,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'fechaSolicitudStr',
      headerName: 'Solicitud',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'fechaCita',
      headerName: 'Cita',
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        if (params.value === null || params.value === undefined) {
          params.value = '--';
        } else {
          params.value = moment(new Date(params.value)).format('YYYY-MM-DD HH:mm:ss');
        }
        return renderCellExpand(params);
      }
    },
    {
      field: 'descEstado',
      headerName: 'Estado',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'descOrdenEstatus',
      headerName: 'Estatus',
      minWidth: 110,
      flex: 1,
      renderCell: ChipEstatusTable
    },
    {
      field: 'acciones',
      headerName: 'Acciones',
      maxWidth: 60,
      minWidth: 60,
      sortable: false,
      disableClickEventBubbling: true,
      align: 'center',
      renderCell: (params) => {
        const onClick = () => {
          onClickEdit(params.row.id);
        };
        return (
          <div>
            <Tooltip title="Consultar">
              <IconButton
                className={classes.customHoverFocus}
                onClick={onClick}
                fontSize="small"
                color="primary"
              >
                <Visibility fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  return (
    <Page
      className={classes.root}
    >
      <Container maxWidth="lg">
        <OTClienteToolBar
          openAltaOrden={openAltaOrden}
          setOpenAltaOrden={setOpenAltaOrden}
          rowsOrdenes={rowsOrdenes}
          setRowsOrdenes={setRowsOrdenes}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setServerError={setServerError}
          setOpenSnackError={setOpenSnackError}
          setOpenBackDrop={setOpenBackDrop}
          rolUsuario={rolUsuario}
          comboOrdenEstatus={comboOrdenEstatus}
          {...props}
        />
        <Box mt={3} />
        <DataTable
          rows={rowsOrdenes}
          columns={columns}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          activateCheckBox={rolUsuario === 'ADMINISTRADOR'}
        />
        <DialogForms
          setOpenDialog={setOpenAltaOrden}
          openDialog={openAltaOrden}
          submitButtonName={idOrden === 0 ? 'GUARDAR' : undefined}
          titulo={idOrden === 0 ? 'Alta de Orden' : 'Editar Orden'}
          formName={formName}
          actionButtonName={idOrden === 0 ? 'LIMPIAR' : 'CERRAR'}
          handleRef={resetOrderRef}
          size="md"
        >
          <AltaClienteOT
            formName={formName}
            ref={ordenRef}
            idOrden={idOrden}
            setIdOrden={setIdOrden}
            formData={formData}
            setFormData={setFormData}
            rowsOrdenes={rowsOrdenes}
            comboOrdenEstatus={comboOrdenEstatus}
            comboHiperliga={comboHiperliga}
            comboGradoEscolar={comboGradoEscolar}
            setComboGradoEscolar={setComboGradoEscolar}
            mapaUsuarios={mapaUsuarios}
            setMapaUsuarios={setMapaUsuarios}
            setServerError={setServerError}
            setOpenSnackError={setOpenSnackError}
            setOpenAltaOrden={setOpenAltaOrden}
            rolUsuario={rolUsuario}
            {...props}
          />
        </DialogForms>
      </Container>
    </Page>
  );
};

ClienteOT.propTypes = {
  setComboOrdenEstatus: PropTypes.func,
  comboOrdenEstatus: PropTypes.array,
  setComboHiperliga: PropTypes.func,
  comboHiperliga: PropTypes.array,
  setOpenBackDrop: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setServerError: PropTypes.func,
  rolUsuario: PropTypes.string
};
export default ClienteOT;
