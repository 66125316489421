import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Tooltip,
  Button,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  FormHelperText,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  colors,
} from '@material-ui/core';
import Page from 'src/components/Page';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import hiperligaService from 'src/services/hiperligaService';
import GeneralBackDrop from 'src/views/commons/GeneralBackDrop';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  Delete,
  DoubleArrow,
  GetAppTwoTone,
  Save
} from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import renderCellExpand from 'src/views/commons/RenderCellExpand';
import ConfirmationDialog from 'src/views/commons/ConfirmationDialog';
import { Download } from 'react-feather';
import combosService from 'src/services/combosService';
import GeneralDialog from 'src/views/commons/GeneralDialog';
import { Autocomplete } from '@material-ui/lab';
import genService from 'src/services/generalServices';
import { useForm } from 'react-hook-form';
import AvisoPrivacidad from './AvisoPrivacidad';
import SummaryDocs from './SummaryDocs';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& .MuiDataGrid-window': {
      bottom: 'auto'
    },
    '& .summary-header': {
      backgroundColor: colors.green[200]
    }
  },
  checkBoxVerde: {
    color: '#4caf50 !important'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  },
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  dropZoneStyle: {
    minHeight: '10px',
  },
  dropZoneText: {
    fontWeight: '200',
    fontFamily: 'sans-serif'
  },
  fontStyle: {
    fontSize: 8
  },
  paddingGrid: {
    marginTop: theme.spacing(2)
  },
  paddingRight: {
    marginRight: theme.spacing(2)
  },
  errorStyle: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center'
  }
}));

const CargaDocs = ({
  className,
  setServerError,
  setServerMessage,
  setOpenSnackError,
  setOpenSnackSuccess
}) => {
  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    setError
  } = useForm();
  const classes = useStyles();

  const [fechaFinalizacion, setFechaFinalizacion] = useState(null);
  const [nombreInvestigado, setNombreInvestigado] = useState('');
  const [deleteFileRow, setDeleteFileRow] = useState(null);
  const [estatusPagina, setEstatusPagina] = useState();
  const [showFinalized, setShowFinalized] = useState(false);
  const [openFinalizar, setOpenFinalizar] = useState(false);
  const [nombreCliente, setNombreCliente] = useState('');
  const [aceptarAviso, setAceptarAviso] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorGeneral, setErrorGeneral] = useState('');
  const [fileSelected, setFileSelected] = useState([]);
  const [openEliminar, setOpenEliminar] = useState(false);
  const [rowsFiles, setRowsFiles] = useState([]);
  const [fileTypes, setFilesTypes] = useState([]);
  const [openAviso, setOpenAviso] = useState(false);
  const [finalizar, setFinalizar] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [ligaKey, setLigaKey] = useState('');
  const [idOrden, setIdOrden] = useState(0);
  const [folio, setFolio] = useState('');
  const [onFinalizar, setOnFinalizar] = useState(false);
  const [open, setOpen] = useState(false);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [openRejected, setOpenRejected] = useState(false);
  const [rowsRejected, setRowsRejected] = useState([]);

  const columnsRejected = [
    {
      field: 'fileName',
      headerName: 'Nombre del Archivo',
      minWidth: 200,
      flex: 1,
      renderCell: renderCellExpand,
      headerClassName: 'summary-header'
    },
    {
      field: 'rechazo',
      headerName: 'Razón de Rechazo',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: renderCellExpand,
      headerClassName: 'summary-header'
    }
  ];

  useEffect(() => {
    const orderKey = encodeURIComponent(searchParams.get('key'));
    setLigaKey(orderKey);
    console.log(setSearchParams);
    const getOrdenData = async (key) => {
      setOpenBack(true);
      await combosService.comboFileTypeLink().then((resp) => {
        setFilesTypes(resp);
      }).catch((error) => {
        console.log('Error al cargar combo de tipos de archivo', error);
      });
      await hiperligaService.getOrdenByKey(key).then((datos) => {
        console.log('FileType', fileTypes);
        setEstatusPagina(true);
        setIdOrden(datos.data.id);
        setNombreInvestigado(datos.data.nombre);
        setNombreCliente(datos.data.nombreCliente);
        setFolio(datos.data.folio);
        setAceptarAviso(datos.data.aceptarAviso);
        if (datos.data.archivos !== null && datos.data.archivos !== undefined
          && datos.data.archivos.length > 0) {
          setRowsFiles(datos.data.archivos);
        }
        console.log('fechafinalizacion:', datos.data);
        if (datos.data.descEstatusLiga === 'FINALIZADA') {
          setShowFinalized(true);
          setFechaFinalizacion(datos.data.fechaFinalizacionLigaStr);
        }
      }).catch((error) => {
        if (error.response !== undefined) {
          setErrorGeneral(`${error.response.data.status}: ${error.response.data.message}`);
        } else {
          setErrorGeneral(`${error.message}: No fue posible conectar con el servidor`);
        }
        setOpenBack(false);
        setEstatusPagina(false);
      });
      setOpenBack(false);
    };
    getOrdenData(orderKey);
  }, []);

  const descargaMasiva = async () => {
    setOpenBack(true);
    const selectedRows = rowsFiles.filter(({ id }) => fileSelected.includes(id));
    const files = selectedRows.filter((auxFile) => auxFile.archivo !== undefined)
      .map((filteredFile) => filteredFile.archivo);
    const filesObject = selectedRows.filter((auxFile) => auxFile.estatus !== 'NUEVO');
    await hiperligaService.descargarZip(filesObject, files, ligaKey).then((response) => {
      console.log('Respuesta descarga zip', response);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response.data);
      link.download = `${folio}.zip`;
      link.click();
    }).catch((e) => {
      console.log('Error al descargar archivos:', e);
      setOpenBack(false);
      setServerError(e.message);
      setOpenSnackError(true);
    });
    setOpenBack(false);
  };

  const downloadVoucher = async () => {
    setOpenBack(true);
    const hiperligaDTO = {
      keyLink: ligaKey,
      idOrden,
      listaArchivos: rowsFiles
    };
    await hiperligaService.descargarPDF(hiperligaDTO).then((resp) => {
      const link = document.createElement('a');
      console.log('Datos del PDF:', resp);
      link.href = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
      link.download = `COMPROBANTE_${folio}.pdf`;
      link.click();
    }).catch((e) => {
      console.log('Error al descargar el archivo:', e);
      setOpenBack(false);
      setServerError(e.message);
      setOpenSnackError(true);
    });
    setOpenBack(false);
  };

  const onClickRemove = async () => {
    setOpenBack(true);
    setOpenEliminar(false);
    let selectedRows = [];
    let nonSelectedRows = [];
    console.log('lista archivos', rowsFiles);
    console.log('archivo selec', deleteFileRow);
    if (deleteFileRow !== null) {
      selectedRows = rowsFiles.filter((row) => (
        row.id === deleteFileRow.id && row.enServidor
      ));
      nonSelectedRows = rowsFiles.filter((row) => row.id !== deleteFileRow.id);
    } else {
      selectedRows = rowsFiles.filter(({ id, enServidor }) => (
        fileSelected.includes(id) && enServidor
      ));
      nonSelectedRows = rowsFiles.filter(({ id }) => !fileSelected.includes(id));
    }
    if (selectedRows.length > 0) {
      const hiperligaDTO = {
        keyLink: ligaKey,
        idOrden,
        listaArchivos: selectedRows
      };
      await hiperligaService.deleteOrderFile(hiperligaDTO).then((resp) => {
        console.log('Ordenes filtradas:', nonSelectedRows);
        setRowsFiles(nonSelectedRows);
        setOpenSnackSuccess(true);
        setServerMessage(resp.data.mensaje);
      }).catch((error) => {
        setOpenSnackError(true);
        if (error.response) {
          setServerError(`${error.response.data.status}: ${error.response.data.message}`);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
      });
    } else {
      setRowsFiles(nonSelectedRows);
    }
    setDeleteFileRow(null);
    setOpenBack(false);
  };

  const agregaArchivos = (fileList) => {
    let filesList = [];
    console.log('inside agregar archivos', fileList);
    const uploadFileToS3 = async (file) => {
      setOpenBack(true);
      console.log('the length of files list:', fileList.length);
      if (filesList.length === 0) {
        filesList = rowsFiles.slice();
        console.log('the length of files list after slice:', fileList.length);
      }
      console.log('the file list', rowsFiles);
      const existingFile = filesList.findIndex((auxFile) => auxFile.nombre === file.name);
      const currentFile = rowsFiles.filter((auxFile) => auxFile.nombre === file.name);
      console.log('The current file', currentFile[0]);
      let nombreArchivo = file.name;
      const nombreSinExtension = nombreArchivo.substring(0, nombreArchivo.lastIndexOf('.'));
      if (nombreSinExtension.length > 50) {
        const extension = nombreArchivo.substring(nombreArchivo.lastIndexOf('.'), nombreArchivo.length);
        const nombreCorto = nombreSinExtension.substring(0, 50);
        console.log('Longitud del nombre', nombreArchivo.length);
        console.log('Extension del archivo', extension);
        console.log('nombreCorto', nombreCorto + extension);
        nombreArchivo = nombreCorto.trim() + extension;
        setOpenSnackError(true);
        setServerError('El nombre de alguno de sus archivos fue reducido a 50 caracteres.');
      }
      let uniqueId;
      if (currentFile && currentFile.length > 0) {
        console.log('inside current file', currentFile);
        uniqueId = currentFile[0].keyIndex;
      } else {
        console.log('new UUID');
        uniqueId = crypto.randomUUID();
      }
      const fileNewName = new File([file], `${uniqueId}<*>${ file.name }`, { type: file.type });
      console.log('adding new file name:', fileNewName);
      if (existingFile < 0) {
        const itemFile = {
          id: `${folio}_${nombreArchivo}`,
          nombre: nombreArchivo,
          size: file.size,
          fileBucketName: `CARGADOC_${folio}_${nombreArchivo}`,
          comentarios: '',
          estatus: 'NUEVO',
          tipoArchivo: '',
          idTipoArchivo: 0,
          archivo: fileNewName,
          enServidor: false,
          keyIndex: uniqueId
        };
        filesList.push(itemFile);
      } else {
        filesList[existingFile].archivo = fileNewName;
        filesList[existingFile].size = file.size;
        if (filesList[existingFile].estatus !== 'NUEVO') {
          filesList[existingFile].estatus = 'ACTUALIZADO';
        }
      }
      /* await ordenService.uploadArchivoAWS(uploadedFile, file); */
      setOpenBack(false);
    };

    fileList.forEach((file) => {
      uploadFileToS3(file);
    });
    if (filesList.length > 0) {
      setRowsFiles(filesList);
    }
  };

  const onSubmit = () => {
    const handleAsync = async (hiperligaDTO) => {
      setOpenBack(true);
      await hiperligaService.actualizarArchivos(hiperligaDTO).then((resp) => {
        console.log('fecha finaliza:', resp.data);
        if (resp.data.generalMessage === 'LIGA FINALIZADA') {
          setShowFinalized(true);
          setFechaFinalizacion(resp.data.fechaFinalizacionLigaStr);
          setOpen(true);
        }
        setRowsFiles(resp.data.archivos);
        setOpenSnackSuccess(true);
        setServerMessage(resp.data.generalMessage);
      }).catch((error) => {
        setOpenSnackError(true);
        if (error.response) {
          setServerError(`${error.response.data.status}: ${error.response.data.message}`);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
        console.log('error al actualizar los archivos', error);
      });
      setOpenBack(false);
    };

    let altaArchivos = true;
    const archivoDTO = rowsFiles.map((auxFile) => {
      if (auxFile.estatus === 'NUEVO') {
        auxFile.id = 0;
      }
      if (auxFile.tipoArchivo === undefined || auxFile.tipoArchivo === '') {
        setOpenSnackError(true);
        setServerError('Debe seleccionar un "Tipo de Archivo" para cada registro.');
        altaArchivos = false;
      }
      return auxFile;
    });

    if (altaArchivos) {
      const itemDTO = {
        keyLink: ligaKey,
        idOrden,
        listaArchivos: archivoDTO,
        avisoPrivacidad: aceptarAviso,
        finalizar
      };
      handleAsync(itemDTO);
    }
  };

  const columnsFiles = [
    {
      field: 'tipoArchivo',
      headerName: 'Tipo de Archivo',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (rowParam) => {
        const { row } = rowParam;
        const defVal = fileTypes.findIndex((auxVal) => auxVal.value === row.idTipoArchivo);
        const rowIndex = rowParam.api.getAllRowIds().indexOf(rowParam.id);
        const nameComponent = `TipoArchivo_${rowIndex}`;
        return (
          <Autocomplete
            id={nameComponent}
            size="small"
            fullWidth
            options={fileTypes}
            defaultValue={fileTypes[defVal]}
            getOptionLabel={(option) => (option.label ? option.label : '')}
            renderInput={(params) => (
              <TextField
                {...params}
                name={nameComponent}
                inputRef={register({
                  required: { value: true, message: 'Este campo es requerido' }
                })}
                error={!!errors[nameComponent]}
                helperText={errors[nameComponent]?.message}
                variant="outlined"
                placeholder="Tipo de Archivo"
              />
            )}
            style={{ lineHeight: '0px' }}
            onChange={(e, newValue) => {
              console.log('changing', nameComponent);
              clearErrors(nameComponent);
              rowParam.row.tipoArchivo = newValue ? newValue.label : '';
              rowParam.row.idTipoArchivo = newValue ? newValue.value : '';
            }}
          />
        );
      }
    },
    {
      field: 'nombre',
      headerName: 'Nombre de Archivo',
      minWidth: 150,
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'comentarios',
      headerName: 'Comentarios',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const { row } = params;
        return (
          <TextField
            fullWidth
            multiline
            placeholder="Ingrese máximo 150 caractéres"
            maxRows={2}
            defaultValue={row.comentarios}
            variant="standard"
            onInput={genService.toUpperCase}
            inputProps={{
              style: { fontSize: 12, padding: 5 },
              maxLength: 150
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              params.row.comentarios = e.target.value;
            }}
          />
        );
      }
    },
    {
      field: 'acciones',
      headerName: 'Acciones',
      maxWidth: 60,
      minWidth: 60,
      sortable: false,
      disableClickEventBubbling: true,
      align: 'center',
      renderCell: (params) => {
        const onClickDownload = () => {
          const link = document.createElement('a');
          if (params.row.archivo !== undefined) {
            link.href = window.URL.createObjectURL(params.row.archivo);
            link.download = params.row.nombre;
            link.click();
          } else {
            const downloadFileAsync = async (datos) => {
              setOpenBack(true);
              console.log(datos);
              await hiperligaService.downloadArchivoAWS(datos).then((resp) => {
                link.href = window.URL.createObjectURL(resp.data);
                params.row.archivo = resp.data;
                link.download = params.row.nombre;
                link.click();
              }).catch((e) => {
                console.log('Error al descargar el archivo', e);
                setOpenBack(false);
                setServerError(e.message);
                setOpenSnackError(true);
              });
              setOpenBack(false);
            };
            downloadFileAsync(params.row);
          }
        };
        return (
          <div>
            <Tooltip title="Eliminar">
              <IconButton
                className={classes.customHoverFocus}
                onClick={() => {
                  setOpenEliminar(true);
                  setDeleteFileRow(params.row);
                }}
                fontSize="small"
                color="primary"
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Descargar">
              <IconButton
                className={classes.customHoverFocus}
                onClick={onClickDownload}
                fontSize="small"
                color="primary"
              >
                <Download fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  return (
    <Page
      className={classes.root}
    >
      <Typography className={classes.errorStyle}>
        Carga de Documentos
      </Typography>
      {
        estatusPagina === undefined || estatusPagina === false
          ? (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <Container maxWidth="md">
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="h1"
                >
                  {
                    estatusPagina === undefined
                      ? (
                        'Cargando...'
                      ) : (
                        errorGeneral
                      )
                  }
                </Typography>
              </Container>
            </Box>
          ) : (
            <div className={clsx(classes.title, className)}>
              <Card>
                <CardHeader title={`${nombreCliente} - ${nombreInvestigado}`} />
                <Divider />
                <CardContent>
                  {
                    showFinalized
                      ? (
                        <SummaryDocs
                          fechaFinalizacion={fechaFinalizacion}
                          rowsFiles={rowsFiles}
                          downloadVoucher={downloadVoucher}
                        />
                      ) : (
                        <form id="cargaDocsForm" onSubmit={handleSubmit(onSubmit)}>
                          <Grid container spacing={3} justifyContent="center">
                            <Grid item md={8} xs={8}>
                              <DropzoneArea
                                dropzoneClass={classes.dropZoneStyle}
                                acceptedFiles={[
                                  'application/pdf',
                                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                  'application/x-zip-compressed',
                                  'application/msword',
                                  'application/vnd.ms-excel',
                                  '.ppt',
                                  '.pttx',
                                  'text/plain',
                                  'image/png',
                                  'image/jpeg',
                                ]}
                                onDrop={(file) => {
                                  let cont = 0;
                                  if (rejectedFiles && rejectedFiles.length > 0) {
                                    rejectedFiles.forEach((fileRej) => {
                                      cont++;
                                      setRowsRejected((rowRejected) => [...rowRejected, {
                                        id: cont,
                                        fileName: fileRej.name,
                                        rechazo: `El tamaño del archivo es de ${fileRej.size / 1000000} MB, no puede ser mayor a 3MB`
                                      }]);
                                    });
                                  }
                                  if (file && file.length > 0) {
                                    file = file.filter((fileItem) => {
                                      const nombreArchivo = fileItem.name;
                                      const nombreSinExtension = nombreArchivo.substring(0, nombreArchivo.lastIndexOf('.'));
                                      if (nombreSinExtension.length <= 50) {
                                        return true;
                                      }
                                      console.log('archivo mayor a 50 chars', fileItem.name);
                                      cont++;
                                      setRowsRejected((rowRejected) => [...rowRejected, {
                                        id: cont,
                                        fileName: fileItem.name,
                                        rechazo: `Este nombre de archivo tiene ${nombreSinExtension.length} caracteres, no puede ser mayor a 50`
                                      }]);
                                      return false;
                                    });
                                  }
                                  console.log('The rows rejected:', rowsRejected);
                                  if (cont > 0) {
                                    setOpenRejected(true);
                                  }
                                  agregaArchivos(file);
                                }}
                                filesLimit={500}
                                dropzoneText={(
                                  <div>
                                    <div>Arrastre y suelte un archivo.</div>
                                    <div style={{ fontWeight: '100', fontFamily: 'sans-serif', fontStyle: 'italic' }}>
                                      (Máx 3 MB y con extensiones: .jpg, .png, .pdf,
                                      .zip, .doc-x, .xls-x, .ppt-x y .txt)
                                    </div>
                                    <div style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontStyle: 'italic' }}>
                                      (El nombre de los archivos no puede ser mayor a 50 caracteres)
                                    </div>
                                  </div>
                                )}
                                maxFileSize={3200000}
                                showPreviewsInDropzone={false}
                                alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
                                showAlerts={['error']}
                                getDropRejectMessage={(obj) => {
                                  console.log('rejected files', obj);
                                  rejectedFiles.push(obj);
                                }}
                              />
                            </Grid>
                          </Grid>
                          {fileSelected.length > 1 ? (
                            <Grid
                              container
                              spacing={3}
                              justifyContent="center"
                              style={{ display: rowsFiles.length > 0 ? '' : 'none' }}
                            >
                              <Grid item md={12} xs={12} sm={12} align="right">
                                <Tooltip title="Eliminar selección">
                                  <Button
                                    className={classes.paddingRight}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Delete />}
                                    onClick={() => setOpenEliminar(true)}
                                  >
                                    ELIMINAR
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Descargar selección">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<GetAppTwoTone />}
                                    onClick={descargaMasiva}
                                  >
                                    DESCARGAR
                                  </Button>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          ) : ''}
                          <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            style={{ display: rowsFiles.length > 0 ? '' : 'none' }}
                          >
                            <Grid item md={12} xs={12} sm={12} align="center">
                              <DataGrid
                                rows={rowsFiles}
                                columns={columnsFiles}
                                autoHeight
                                disableColumnFilter
                                disableColumnMenu
                                hideFooter
                                checkboxSelection
                                disableSelectionOnClick
                                onSelectionModelChange={(selec) => {
                                  console.log('La seleccion de archivo:', selec);
                                  setFileSelected(selec);
                                }}
                                style={{ bottom: 'auto' }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            style={{ display: rowsFiles.length > 0 ? '' : 'none' }}
                          >
                            <Grid item md={12} xs={12} sm={12} align="center">
                              <FormControl required error={!!errors} align="center">
                                <FormControlLabel
                                  control={(
                                    <Checkbox
                                      className={classes.checkBoxVerde}
                                      name="aceptarAviso"
                                      inputRef={register({
                                        required: { value: onFinalizar, message: 'Es necesario aceptar el aviso de privacidad' }
                                      })}
                                      color="primary"
                                      checked={aceptarAviso}
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          clearErrors(['aceptarAviso']);
                                        }
                                      }}
                                    />
                                  )}
                                  onChange={(e) => setAceptarAviso(e.target.checked)}
                                  label={(
                                    <Typography
                                      variant="h5"
                                    >
                                      {'He leído y acepto el aviso de privacidad -- '}
                                      <Link
                                        variant="body2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setOpenAviso(true);
                                        }}
                                      >
                                        CONSULTAR
                                      </Link>
                                    </Typography>
                                  )}
                                />
                                <FormHelperText
                                  className={classes.errorStyle}
                                >
                                  {errors.aceptarAviso?.message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            style={{ display: rowsFiles.length > 0 ? '' : 'none' }}
                            className={classes.paddingGrid}
                          >
                            <Grid item md={12} xs={12} align="center">
                              <Button
                                variant="contained"
                                color="primary"
                                endIcon={<Save />}
                                className={classes.paddingRight}
                                onClick={() => setOnFinalizar(false)}
                                type="submit"
                              >
                                GUARDAR
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                endIcon={<DoubleArrow />}
                                onClick={() => {
                                  setOnFinalizar(true);
                                  if (!aceptarAviso) {
                                    setError('aceptarAviso', { type: 'focus', message: 'Es necesario aceptar el aviso de privacidad' });
                                  } else {
                                    setOpenFinalizar(true);
                                  }
                                }}
                              >
                                FINALIZAR
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      )
                  }
                </CardContent>
              </Card>
            </div>
          )
      }
      <AvisoPrivacidad
        openAviso={openAviso}
        setOpenAviso={setOpenAviso}
        setAceptarAviso={setAceptarAviso}
        clearErrors={clearErrors}
      />
      <GeneralBackDrop open={openBack} />
      <ConfirmationDialog
        keepMounted
        open={openEliminar}
        onClose={() => {
          setOpenEliminar(false);
          setDeleteFileRow(null);
        }}
        confirm={onClickRemove}
        title="Eliminar archivos"
        message={deleteFileRow === null ? `Seguro que desea eliminar los ${fileSelected.length} archivos seleccionados?` : 'Seguro que desea eliminar el archivo?'}
      />
      <Dialog
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={openFinalizar}
      >
        <DialogTitle id="confirmation-dialog-title">
          Finalizar Carga de Archivos
        </DialogTitle>
        <DialogContent dividers>
          Esta seguro que ha finalizado la carga de archivos?
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenFinalizar(false);
              setFinalizar(false);
            }}
            color="primary"
          >
            CANCELAR
          </Button>
          <Button
            form="cargaDocsForm"
            type="submit"
            color="primary"
            onClick={() => {
              setFinalizar(true);
              setOpenFinalizar(false);
            }}
          >
            FINALIZAR
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '100%'
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={openRejected}
      >
        <DialogTitle id="confirmation-dialog-title">
          Error en la Carga de Archivos
        </DialogTitle>
        <DialogContent dividers>
          <DataGrid
            rows={rowsRejected}
            columns={columnsRejected}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableVirtualization
            hideFooter
            disableSelectionOnClick
            style={{ bottom: 'auto' }}
          />
          <div style={{ fontWeight: 'bold', color: 'red' }}>* Favor de corregir los errores y volver a intentar la carga de los archivos.</div>
        </DialogContent>
        <DialogActions>
          <Button
            form="cargaDocsForm"
            type="submit"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              setRejectedFiles([]);
              setRowsRejected([]);
              setOpenRejected(false);
            }}
          >
            ENTERADO
          </Button>
        </DialogActions>
      </Dialog>
      <GeneralDialog
        open={open}
        changeState={() => {
          setOpen(false);
        }}
        title="Documentos enviados"
        message="Sus documentos han sido enviados, a continuación podrá descargar su comprobante. Muchas gracias."
      />
    </Page>
  );
};

CargaDocs.propTypes = {
  className: PropTypes.string,
  setServerError: PropTypes.func,
  setServerMessage: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
};

export default CargaDocs;
